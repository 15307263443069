const defaultState = () => ({
  cameras: [],
  camerasPerRow: 1,
  camerasPerRowFullScreen: 3,
  fullScreen: false,
  showVideoBars: true,
});

const getters = {
  getCameras: (state) => state.cameras
};

const actions = {
  addCamera({ commit }, item) {
    commit('ADD_CAMERA', item)
  },
  removeCamera({ commit }, index) {
    commit('REMOVE_CAMERA', index)
  },
  removeAllCameras({ commit }) {
    commit('REMOVE_ALL_CAMERAS')
  },
  setCamerasPerRow({ commit }, num) {
    commit('SET_CAMERAS_PER_ROW', num)
  },
  toggleFullScreen({ commit }) {
    commit('TOGGLE_FULL_SCREEN')
  },
  toggleShowVideoBars({ commit }) {
    commit('TOGGLE_SHOW_VIDEO_BARS')
  },
  clearStore({ commit }) {
    commit('CLEAR_STORE')
  },
};

const mutations = {
  ADD_CAMERA(state, item) {
    state.cameras.unshift(item)
  },
  REMOVE_CAMERA(state, index) {
    state.cameras.splice(index, 1)
  },
  REMOVE_ALL_CAMERAS(state) {
    state.cameras = []
    state.fullScreen = false
  },
  SET_CAMERAS_PER_ROW(state, num) {
    if (state.fullScreen) {
      state.camerasPerRowFullScreen = num
    } else {
      state.camerasPerRow = num
    }
  },
  TOGGLE_FULL_SCREEN(state) {
    state.fullScreen = !state.fullScreen
  },
  TOGGLE_SHOW_VIDEO_BARS(state) {
    state.showVideoBars = !state.showVideoBars
  },
  CLEAR_STORE(state) {
    Object.assign(state, defaultState())
  }
};

export default {
  namespaced: true,
  state() {
    return defaultState()
  },
  getters,
  actions,
  mutations
};
