import { format } from 'date-fns';
import moment from 'moment';

const formatDate = (d, dateFormat = 'MM/dd/yyyy') => format(d, dateFormat);

// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }
const randomElement = (arr = []) => arr[Math.floor(Math.random() * arr.length)];

const kebab = (str) => (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const toggleFullScreen = () => {
  const doc = window.document;
  const docEl = doc.documentElement;
  const requestFullScreen = docEl.requestFullscreen
    || docEl.mozRequestFullScreen
    || docEl.webkitRequestFullScreen
    || docEl.msRequestFullscreen;
  const cancelFullScreen = doc.exitFullscreen
    || doc.mozCancelFullScreen
    || doc.webkitExitFullscreen
    || doc.msExitFullscreen;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement
    && !doc.webkitFullscreenElement && !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};

const statusMap = {
  up: 'En linea',
  down: 'Fuera de linea',
  online: 'En linea',
  offline: 'Fuera de linea',
  unknown: 'Desconocido',
  alert: 'En alerta'
}

const incidentsAlertMap = {
  seguridad: {
    color: 'var(--kalan-blue)',
    icon: 'mdi-alert-outline'
  },
  medico: {
    color: 'var(--kalan-red)',
    icon: 'mdi-alert-outline'
  },
  proteccion_civil: {
    color: 'var(--kalan-orange)',
    icon: 'mdi-alert-outline'
  },
  servicios_publicos: {
    color: 'var(--kalan-green)',
    icon: 'mdi-alert-outline'
  }
}

export const periods = [{
  id: 'custom',
  name: 'Personalizado',
  getPeriod: ({
    to, toHour, from, fromHour
  }) => {
    const fromSplit = fromHour.split(':')
    const toSplit = toHour.split(':')
    return {
      from: new Date(from.setHours(fromSplit[0], fromSplit[1] || 0, 0, 0)),
      to: new Date(to.setHours(toSplit[0], toSplit[1] || 0, 0, 0))
    }
  }
}, {
  id: 'today',
  name: 'Hoy',
  getPeriod: () => {
    return {
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate()
    }
  }
}, {
  id: 'yesterday',
  name: 'Ayer',
  getPeriod: () => {
    return {
      from: moment().subtract(1, 'day').startOf('day').toDate(),
      to: moment().subtract(1, 'day').endOf('day').toDate()
    }
  }
}, {
  id: 'week',
  name: 'Semana Actual',
  getPeriod: () => {
    return {
      from: moment().startOf('week').toDate(),
      to: moment().endOf('week').toDate()
    }
  }
}, {
  id: 'lastWeek',
  name: 'Semana Anterior',
  getPeriod: () => {
    return {
      from: moment().subtract(1, 'week').startOf('week').toDate(),
      to: moment().subtract(1, 'week').endOf('week').toDate()
    }
  }
}, {
  id: 'mounth',
  name: 'Mes Actual',
  getPeriod: () => {
    return {
      from: moment().startOf('month').toDate(),
      to: moment().endOf('month').toDate()
    }
  }
}, {
  id: 'lastMonth',
  name: 'Mes Anterior',
  getPeriod: () => {
    return {
      from: moment().subtract(1, 'month').startOf('month').toDate(),
      to: moment().subtract(1, 'month').endOf('month').toDate()
    }
  }
}]

export default {
  randomElement,
  toggleFullScreen,
  formatDate,
  kebab,
  statusMap,
  incidentsAlertMap
}
